<template>
  <section
    :class="[
      'ASOMSShiftHandoverAccordionItem',
      isExpanded && 'ASOMSShiftHandoverAccordionItem--is-expanded'
    ]"
  >
    <button 
      class="ASOMSShiftHandoverAccordionItem__Toggle"
      :aria-expanded="isExpanded" 
      @click="onToggled()">
      <div class="flex-grow text-left flex items-center space-x-2">
        <h3 class="text-lg  font-semibold">{{ title }}</h3>
        <span v-if="isLoading" class="flex items-center space-x-1">
          <asom-icon icon="spinner" size="sm" class="animate-spin" />
          <span class="text-sm">Loading data...</span>
        </span>
        <asom-badge v-else :variant="statusVariant">
          {{statusText}}
        </asom-badge>
      </div>
      <asom-icon :icon="isExpanded ? 'chevron-up' : 'chevron-down'" size="md" />
    </button>
    
    <transition name="transition-fade">
      <div class="ASOMSShiftHandoverAccordionItem__Content" v-if="isExpanded">
        <!-- @slot accordion content -->
        <slot></slot>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      default: 'aligned',
      validator: (val) => [ 'loading', 'aligned', 'discrepant', 'resolved', 'error' ].includes(val),
    },
  },
  emits: [ 'toggle' ],
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    isLoading() {
      return this.status === 'loading';
    },
    isErrorStatus() {
      return this.status === 'error';
    },
    statusVariant() {
      switch (this.status) {
        case 'error':
          return 'error';
        case 'aligned':
          return 'success';
        case 'discrepant':
          return 'error';
        case 'resolved':
          return 'success';
        default:
          return 'secondary';
      }
    },
    statusText() {
      switch (this.status) {
        case 'error':
          return 'Error';
        case 'aligned':
          return 'Aligned';
        case 'discrepant':
          return 'Additional Info Missing';
        case 'resolved':
          return 'Resolved';
        default:
          return '';
      }
    }
  },
  methods: {
    onToggled() {
      this.isExpanded = !this.isExpanded;
      this.$emit('toggle', this.isExpanded);
    },
  }
}
</script>
<style lang="scss" scoped>
.ASOMSShiftHandoverAccordionItem {
  @apply rounded-t-md  rounded-b-md overflow-hidden;
  
  .ASOMSShiftHandoverAccordionItem__Toggle {
    @apply flex w-full bg-blue-100 px-4 py-4 rounded-t-md;

    .ASOMSShiftHandoverAccordionItem__Status {
      @apply flex flex-row text-green-700 px-2;

      &--status-error {
        @apply text-red-700;
      }
    }
  }

  .ASOMSShiftHandoverAccordionItem__Content {
    @apply block bg-white px-4 py-4 rounded-b-md;
  }

  .ASOMSShiftHandoverAccordionItem__ButtonContainer {
    @apply space-x-2 flex justify-end;
  }
}

.transition-fade-enter-active,
.transition-fade-leave-active {
  @apply transition transition-opacity duration-150 ease-in-out;
}

.transition-fade-enter,
.transition-fade-leave-to {
  @apply opacity-0 h-0;
}


</style>