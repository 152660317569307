<template>
  <shift-handover-accordion-item
    title="Pre Encoded Ticket Summary"
    :status="status"
  >
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <h4 class="text-md font-semibold">Previous Shift Info</h4>
      <asom-client-table
        :filterable="false"
        :pagination="false"
        :columns="[
          'eventName',
          'noOfTickets',
          'noOfTicketsSold',
          'ticketAvailable',
        ]"
        :data="previousShiftData"
      >
        <template v-slot:header_eventName>Event</template>
        <template v-slot:header_noOfTickets>Total</template>
        <template v-slot:header_noOfTicketsSold>Sold</template>
        <template v-slot:header_ticketAvailable>Remaining</template>
        <template v-slot:eventName="scopedSlots">
          <div class="space-x-1">
            {{ scopedSlots.data }}
            <asom-badge
              v-if="scopedSlots.rowData.isClosed"
              variant="secondary"
              >Closed Event</asom-badge
            >
          </div>
        </template>
      </asom-client-table>
      <h4 class="text-md font-semibold">Current Shift Info</h4>
      <asom-client-table
        :filterable="false"
        :pagination="false"
        :columns="[
          'eventName',
          'noOfTickets',
          'noOfTicketsSold',
          'ticketAvailable',
        ]"
        :data="currentShiftData"
      >
        <template v-slot:header_eventName>Event</template>
        <template v-slot:header_noOfTickets>Total</template>
        <template v-slot:header_noOfTicketsSold>Sold</template>
        <template v-slot:header_ticketAvailable>Remaining</template>
        <template v-slot:eventName="scopedSlots">
          <div class="space-x-1">
            {{ scopedSlots.data }}
            <asom-badge
              v-if="scopedSlots.rowData.isClosed"
              variant="secondary"
              >Closed Event</asom-badge
            >
          </div>
        </template>
        <template v-slot:noOfTicketsSold="scopedSlots">
          <div class="space-x-1">
            <span>{{ scopedSlots.data }}</span>
            <span>(${{ scopedSlots.rowData.valueOfSoldTickets }})</span>
          </div>
        </template>
      </asom-client-table>
      <h4 class="text-md font-semibold">Transactions</h4>
      <asom-client-table
        :columns="[
          'transactionNo',
          'eventName',
          'amount',
          'dateCreated',
          'createdBy',
          'noOfTickets',
          'transactionType',
        ]"
        :data="tableData"
        :filterable="false"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:amount="scopedSlots">
          <span>${{ scopedSlots.data }}</span>
        </template>
      </asom-client-table>
      <template v-if="!readonly">
        <asom-form-field label="Confirmation">
          <asom-input-radio-group
            horizontal
            v-model="computedAligned"
            :options="alignOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="!isAligned"
          label="Discrepancy Report"
          required
          description="Discrepancy report must be selected"
          :state="inputStates('preEncodedTicketSummary.discrepancyReports')"
          error="No discrepancy report added"
        >
          <span class="pr-4">{{
            selectedDiscrepancyReports.length > 0
              ? selectedDiscrepancyReports.length + ` Selected `
              : null
          }}</span>
          <asom-button
            :text="selectedDiscrepancyReports.length > 0 ? 'Edit' : 'Add'"
            :icon="selectedDiscrepancyReports.length > 0 ? 'pen' : 'add-r'"
            icon-position="left"
            @click="addReports"
          ></asom-button>
        </asom-form-field>
        <discrepancy-report-selection
          v-model="showReportSelection"
          @selectedItems="setReports($event)"
          :selected-reports="selectedDiscrepancyReports"
        />
      </template>
      <template v-else-if="!isAligned">
        <h4 class="text-md font-semibold">Discrepancy Reports</h4>
        <asom-client-table
          :columns="[
            'no',
            'referenceNo',
            'description',
          ]"
          :data="discrepancyReports"
          :filterable="false"
          :pagination="false"
        > 
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
      </template>
    </div>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import DiscrepancyReportSelection from "./_DiscrepancyReportSelection.vue";
import {
  baseSummaryMixin,
  summaryDiscrepancyReportMixin,
} from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
    DiscrepancyReportSelection,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryDiscrepancyReportMixin],
  data() {
    return {
      showReportSelection: false,
      selectedDiscrepancyReports: [],
    };
  },
  computed: {
    previousShiftData() {
      return get(this.data, "previousShiftData.events", []);
    },
    currentShiftData() {
      return get(this.data, "currentShiftData.events", []);
    },
    tableData() {
      return get(this.data, "transactions", []);
    },
    noOfTickets() {
      return get(this.data, "noOfTickets", "NA");
    },
  },
  methods: {
    displayUtcDate,
    addReports() {
      this.showReportSelection = true;
    },
    setReports(reports) {
      this.selectedDiscrepancyReports = reports;
      this.computedDiscrepancyReports = reports;
    },
  },
};
</script>
