<template>
  <shift-handover-accordion-item
    title="Station Occurrence e-Logbook"
    :status="status"
  >
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <asom-card class="p-2">
        <asom-client-table
          :columns="[
            'stationOccurrenceTransactionNo',
            'transactionType',
            'createdBy',
            'occurrenceNo',
            'isItemOpened',
            'isOutStandingItem',
            'isExpiryDateRequired',
            'quantity',
            'remarks',
            'description',
          ]"
          :data="tableData"
          :filterable="false"
        >
        </asom-client-table>
      </asom-card>
    </div>
    <template v-if="!readonly">
      <asom-form-field label="Confirmation">
        <asom-input-radio-group
          horizontal
          v-model="computedAligned"
          :options="alignOptions"
        />
      </asom-form-field>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
        required
        description="Remarks is required for discrepancy"
        :state="inputStates('faultOccurrenceSummary.remarks')"
        error="Remarks must be between 10 and 1000 characters long"
      >
        <asom-input-textarea
          v-model="computedRemarks"
          :state="inputStates('faultOccurrenceSummary.remarks')"
        />
      </asom-form-field>
    </template>
    <template v-else>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
      >
        <p>{{remarks}}</p>
      </asom-form-field>
    </template>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import { baseSummaryMixin, summaryRemarksMixin } from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryRemarksMixin],
  computed: {
    tableData() {
      return get(this.data, "occurrences", []);
    },
  },
};
</script>
