<template>
  <div class="flex flex-col w-fill">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div
        class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
      >
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <caption class="hidden"></caption>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  Bag Status
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  Closed
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  Opened
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top"
                >
                  Certis Bags (Manual Change)
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  <div v-if="closedBags" class="flex flex-col space-y-1">
                    <div
                      v-for="bag in closedBags"
                      :key="bag.bagId"
                      class="flex flex-row items-center space-x-1 align-center"
                    >
                      <span>
                        {{ bag.bagNo }}
                      </span>
                      <asom-badge v-if="isBagMovedOut(bag)"
                        >In transit</asom-badge
                      >
                    </div>
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top"
                >
                  <div v-if="openBags" class="flex flex-col">
                    <div
                      v-for="bag in openBags"
                      :key="bag.bagId"
                      class="flex flex-row items-center space-x-4 space-y-2 align-center"
                    >
                      <span>
                        {{ bag.bagNo }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot class="bg-gray-50">
              <tr v-if="openBagsTotalAmount || closedBagsTotalAmount">
                <td
                  class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                >
                  Amount of Cash
                </td>
                <td
                  class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                >
                  ${{ closedBagsTotalAmount }}
                </td>
                <td
                  class="px-6 py-3 text-left font-large text-black text-sm font-semibold"
                >
                  ${{ openBagsTotalAmount }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { psc } from "@/constants/APIEnums/cashManagementEnums";
export default {
  props: {
    bags: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    closedBags() {
      return this.bags.filter((bag) => bag.status == "Sealed");
    },
    openBags() {
      return this.bags.filter((bag) => bag.status == "Opened");
    },
    closedBagsTotalAmount() {
      return this.closedBags.reduce((sum, bag) => sum += bag.amount, 0);
    },
    openBagsTotalAmount() {
      return this.openBags.reduce((sum, bag) => sum += bag.amount, 0);
    }
  },
  methods: {
    isBagMovedOut({ lastTransactionTypeEnum }) {
      return lastTransactionTypeEnum == psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.MOVE_OUT.VALUE;
    },
  }
}
</script>

<style>

</style>