import isEmpty from "lodash.isempty";
export const baseSummaryMixin = {
  props: {
    readonly: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    data: {
      type: undefined,
    },
    error: {
      type: Object,
      default: null,
    },
    isAligned: {
      type: Boolean
    },
    discrepancyReports: {
      type: Array,
      default: () => []
    },
    remarks: {
      type: String,
      default: "",
    },
  },
  emits: [ 'update:isAligned' ],
  computed: {
    status() {
      if (this.loading) {
        return 'loading';
      }
      else if (this.error) {
        return 'error'
      }
      else if (this.isAligned) {
        return 'aligned'
      }
      else if (!isEmpty(this.discrepancyReports) || !isEmpty(this.remarks)) {
        return 'resolved'
      }
      return 'discrepant';
    },
    computedAligned: {
      get() {
        return this.isAligned ? 'aligned' : 'discrepant';
      },
      set(val) {
        this.$emit('update:isAligned', val === 'aligned');
      }
    },
    alignOptions() {
      return [
        { value: 'aligned', label: 'Aligned' },
        { value: 'discrepant', label: 'Discrepant' },
      ]
    },
  }
}

export const summaryDiscrepancyReportMixin = {
  emits: [ 'update:discrepancyReports' ],
  computed: {
    computedDiscrepancyReports: {
      get() {
        return this.discrepancyReports;
      },
      set(val) {
        this.$emit('update:discrepancyReports', val);
      }
    },
  }
}

export const summaryRemarksMixin = {
  emits: [ 'update:remarks' ],
  computed: {
    computedRemarks: {
      get() {
        return this.remarks;
      },
      set(val) {
        return this.$emit('update:remarks', val);
      }
    },
  }
}