<template>
  <asom-modal
    v-model="showReportSelection"
    title="Select Reports"
    :dismissible="false"
    size="lg"
  >
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-form-field
      :label="'Discrepancy No'"
      required
      description="Type to search for Discrepancy Reports"
      class="w-72"
    >
      <asom-input-text v-model="discrepancyNo" />
    </asom-form-field>
    <div v-if="!isLoadingDiscrepancyReports" class="pt-6">
      <asom-client-table
        v-if="reports.length > 0"
        :columns="tableData.columns"
        :data="tableData.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_select>
          <input type="checkbox" v-model="multiSelectAll" @change="onSelect" />
        </template>
        <template v-slot:select="scopedSlots">
          <input
            class="mx-2"
            type="checkbox"
            v-model="scopedSlots.rowData.select"
            @change="onSelect"
          />
        </template>
        <template v-slot:transactionNo="scopedSlots">
          <span v-if="scopedSlots.rowData.discrepancyTypeEnum !== 0">
            {{ scopedSlots.data }}
          </span>
          <span v-else>Others</span>
        </template>
        <template v-slot:status="scopedSlots">
          <span>{{ addSpacing(scopedSlots.data) }}</span>
        </template>
        <template v-slot:date="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.rowData.dateTime) }}</span>
        </template>
      </asom-client-table>
    </div>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div class="flex flex-row-reverse pt-4 gap-4">
      <asom-button
        @click="onSubmit"
        :disabled="!selectedItems.length > 0"
        :loading="isLoadingDiscrepancyReports"
        text="Confirm"
      />
      <asom-button
        @click="toggleModal(false)"
        text="Cancel"
        variant="secondary"
      />
    </div>
  </asom-modal>
</template>

<script>
import isEmpty from 'lodash.isempty';
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getDiscrepancyReports } from "@/services/cashManagement.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    modelValue: {
      type: Boolean,
    },
    selectedReports: {
      type: undefined,
    },
  },
  emits: ["update:modelValue", "selectedItems"],
  data() {
    return {
      discrepancyNo: null,
      isLoadingDiscrepancyReports: false,
      error: null,
      reports: [],
      selectedItems: [],
    };
  },
  validations: {
    selectedItems: {
      required,
      minLength: minLength(1),
    },
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
    showReportSelection: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    tableData() {
      let columns = [
        "select",
        "referenceNo",
        "discrepancyType",
        "status",
        "station",
        "date",
      ];
      return {
        columns: columns,
        data: this.reports,
      };
    },
    multiSelectAll: {
      get() {
        return this.reports.every((transaction) => transaction.select);
      },
      set(newValue) {
        this.reports = this.reports.map((transaction) => ({
          ...transaction,
          select: newValue,
        }));
      },
    },
  },
  watch: {
    discrepancyNo: function(newValue) {
      if (newValue) {
        this.queryDiscrepancyReports();
      }
    },
    modelValue: function() {
      if (this.selectedReports.length > 0) {
        this.setInitial();
      } else if (this.showReportSelection) {
        this.queryDiscrepancyReports(false);
      }
    },
  },
  methods: {
    get,
    displayUtcDate,
    setInitial() {
      this.reports = this.selectedReports;
      this.reports = this.reports.map((transaction) => ({
        ...transaction,
        select: true,
      }));
      this.selectedItems = this.reports;
    },
    addSpacing(string) {
      return string.replace(/([A-Z])/g, " $1").trim();
    },
    toggleModal(value) {
      this.$emit("update:modelValue", value);
      this.discrepancyNo = null;
      this.reports = [];
      this.selectedItems = [];
    },
    onSelect() {
      const newValue = [];
      this.$nextTick(() => {
        this.tableData.data.forEach((item) => {
          if (item.select) {
            newValue.push(item);
          }
        });
        this.selectedItems = newValue;
      });
      
    },
    onSubmit() {
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        var items = this.selectedItems;
        this.$emit("selectedItems", items);
        this.toggleModal(false);
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async queryDiscrepancyReports(withQuery = true) {
      let query = this.discrepancyNo || '';
      this.error = null;
      this.isLoadingDiscrepancyReports = true;
      if (withQuery && !query) {
        this.isLoadingDiscrepancyReports = false;
        return [];
      }
      const result = await getDiscrepancyReports({
        search: query.trim(),
        lineId: this.lineId, 
      });
      if (result.success && Array.isArray(get(result, "payload.dicrepancyReports"))) {
        this.isLoadingDiscrepancyReports = false;
        this.error = null;
        const reports = get(result, "payload.dicrepancyReports", []);
        if (isEmpty(reports)) {
          this.error = "No matching discrepancy report found";
        }
        this.reports = reports;
      } else {
        this.isLoadingDiscrepancyReports = false;
        this.error = result.payload;
        return [];
      }
    },
  },
};
</script>
