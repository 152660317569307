<template>
<div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table class="min-w-full divide-y divide-gray-200">
        <caption class="hidden"></caption>
        <thead class="bg-gray-50">
            <tr>
                <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                    Bag Status
                </th>
                <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                    Pending Witness Signature
                </th>
                <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                    Pending Bagger Signature
                </th>
                <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider">
                    Completed
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 align-top">
                    TE Bags
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                    <div v-if="pendingWitnessBags" class="flex flex-col ">
                        <div v-for="bag in pendingWitnessBags" :key="bag.bagId" class="flex flex-row items-center space-x-4 space-y-2 align-center">
                            <div class="text-black">
                                {{ bag.bagNo }}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                    <div v-if="pendingBaggerBags" class="flex flex-col ">
                        <div v-for="bag in pendingBaggerBags" :key="bag.bagId" class="flex flex-row items-center space-x-4 space-y-2 align-center">
                            <div class="text-black">
                                {{ bag.bagNo }}
                            </div>
                        </div>
                    </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top">
                    <div v-if="completedBags" class="flex flex-col ">
                        <div v-for="bag in completedBags" :key="bag.bagId" class="flex flex-row items-center space-x-4 space-y-2 align-center">
                            <div class="text-black">
                                {{ bag.bagNo }}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot class="bg-gray-50">
            <tr>
                <td class="px-6 py-3 text-left font-large text-black text-sm font-semibold">
                    Amount of Cash
                </td>
                <td class="px-6 py-3 text-left font-large text-black text-sm font-semibold">
                    {{ formatCurrency(pendingWitnessBagsTotal) }}
                </td>
                <td class="px-6 py-3 text-left font-large text-black text-sm font-semibold">
                    {{ formatCurrency(pendingBaggerBagsTotal) }}
                </td>
                <td class="px-6 py-3 text-left font-large text-black text-sm font-semibold">
                    {{ formatCurrency(completedBagsTotal) }}
                </td>
            </tr>
        </tfoot>
    </table>
</div>
</template>

<script>
//import get from 'lodash.get';
import { formatCurrency } from "../../../helpers/numbers";

export default {
    props: {
        teBags: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            pendingWitnessBags: [],
            pendingWitnessBagsTotal: 0,
            pendingBaggerBags: [],
            pendingBaggerBagsTotal: 0,
            completedBags: [],
            completedBagsTotal: 0,
        }
    },
    mounted() {
        this.getPendingWitnessBags();
        this.getPendingBaggerBags();
        this.getCompletedBags();
    },
    methods: {
        formatCurrency,
        sumTeBags(bags) {
            if(bags.length === 0)
                return 0;
            return bags.reduce((sum = 0, bag) => sum + bag.amount, 0);
        },
        getPendingWitnessBags() {
            const result = [];
            const len = this.teBags.length;
            for (let i = 0; i < len; i++) {
                if(this.teBags[i].status === "PendingWitnessSignature")
                    result.push(this.teBags[i],);
            }
            this.pendingWitnessBags = result;
            this.pendingWitnessBagsTotal = this.sumTeBags(result);
            return result;
        },
        getPendingBaggerBags() {
            const result = [];
            const len = this.teBags.length;
            for (let i = 0; i < len; i++) {
                if(this.teBags[i].status === "PendingBaggerSignature")
                    result.push(this.teBags[i],);
            }
            this.pendingBaggerBags = result;
            this.pendingBaggerBagsTotal = this.sumTeBags(result);
            return result;
        },
        getCompletedBags() {
            const result = [];
            const len = this.teBags.length;
            for (let i = 0; i < len; i++) {
                if(this.teBags[i].status === "Completed")
                    result.push(this.teBags[i],);
            }
            this.completedBags = result;
            this.completedBagsTotal = this.sumTeBags(result);
            return result;
        },
    }
}
</script>