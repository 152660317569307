<template>
  <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table class="min-w-full divide-y divide-gray-200">
      <caption class="hidden"></caption>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Certis Bags
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            TE Bags
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 text-sm">
        <template v-for="(bagPair, index) in bagPairList" :key="index">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap w-1/2">
              <span>{{ bagPair.certisBagNo }}</span>
              <asom-badge v-if="bagPair.isCertisBagMoveOut" class="ml-2">
                In Transit
              </asom-badge>
              <asom-badge
                v-if="bagPair.isCertisBagInSafe"
                class="ml-2"
                variant="secondary"
              >
                In safe
              </asom-badge>
            </td>
            <td class="px-6 py-4 whitespace-nowrap w-1/2">
              {{ bagPair.teBagNo }}
              <asom-badge
                v-if="bagPair.isTeBagInSafe"
                class="ml-2"
                variant="secondary"
              >
                In safe
              </asom-badge>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
          >
            ${{ sumCertisBags }}
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-lg font-medium text-gray-900 uppercase tracking-wider"
          >
            ${{ sumTeBags }}
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import get from 'lodash.get';
import Decimal from 'decimal.js';

export default {
  props: {
    certisBags: {
      type: Array,
      default: () => []
    },
    teBags: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    bagPairList() {
      const result = [];
      const len =
        this.certisBags.length > this.teBags.length
          ? this.certisBags.length
          : this.teBags.length;
      for (let i = 0; i < len; i++) {
        result.push({
          certisBagNo: get(this.certisBags, `${i}.bagNo`),
          isCertisBagMoveOut:
            get(this.certisBags, `${i}.lastTransaction`) === "Move Out",
          teBagNo: get(this.teBags, `${i}.bagNo`),
          isCertisBagInSafe: get(this.certisBags, `${i}.inSafe`),
          isTeBagInSafe: get(this.teBags, `${i}.inSafe`),
        });
      }
      return result;
    },
    sumCertisBags() {
      //return this.certisBags.reduce((sum, bag) => sum + bag.amount, 0);
      return this.certisBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
    sumTeBags() {
      //return this.teBags.reduce((sum, bag) => sum + bag.amount, 0);
      return this.teBags.reduce((sum, bag) => sum.plus(new Decimal(bag.amount)), new Decimal(0)).toFixed(2);
    },
  }
}
</script>

<style>

</style>