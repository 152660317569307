<template>
  <asom-tooltip v-if="this.text.length > 5">
    <template #toggleText>
      <span>{{ shortened }}</span>
    </template>
    <p>{{ text }}</p>
  </asom-tooltip>
  <p v-else>{{ text }}</p>
</template>

<script>
export default {
  props: ["text"],
  computed: {
    shortened() {
      if (this.text.length > 5) {
        return this.text.substring(0, 5) + "...";
      } else return this.text;
    },
  },
};
</script>
