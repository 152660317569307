<template>
  <shift-handover-accordion-item title="Smart Card Inventory" :status="status">
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <asom-card class="p-2">
        <!-- <asom-form-field label="Smart Card Count">
          <p class="font-semibold">
            {{ smartCardCount }}
          </p>
        </asom-form-field> -->
        <h4 class="text-md font-semibold">Last Shift Info</h4>
        <asom-client-table
          :columns="[
            'inventoryTypeName',
            'quantity',
            'primaryLocationName',
            'secondaryLocationName',
            'expiryDate',
          ]"
          :data="previousShiftData"
          :filterable="false"
        >
          <template v-slot:primaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:secondaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:header_inventoryTypeName>Item Type</template>
          <template v-slot:inventoryTypeName="scopedSlots">
            <div class="StatusBlock">
              <span class="pr-2">{{ scopedSlots.data }}</span>
              <asom-badge
                v-if="get(scopedSlots.rowData, 'isSmartCardBoxOpened')"
                variant="success"
              >
                Open
              </asom-badge>
              <asom-badge v-else class="ClosedStatus" variant="error">
                Closed
              </asom-badge>
            </div>
          </template>
          <template v-slot:header_primaryLocationName>Primary Location</template>
          <template v-slot:header_secondaryLocationName
            >Secondary Location</template
          >
          <template v-slot:expiryDate="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
        </asom-client-table>
        <h4 class="text-md font-semibold">Current Shift Info</h4>
        <asom-client-table
          :columns="[
            'inventoryTypeName',
            'quantity',
            'primaryLocationName',
            'secondaryLocationName',
            'expiryDate',
          ]"
          :data="currentShiftData"
          :filterable="false"
        >
          <template v-slot:primaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:secondaryLocationName="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:header_inventoryTypeName>Item Type</template>
          <template v-slot:inventoryTypeName="scopedSlots">
            <div class="StatusBlock">
              <span class="pr-2">{{ scopedSlots.data }}</span>
              <asom-badge
                v-if="get(scopedSlots.rowData, 'isSmartCardBoxOpened')"
                variant="success"
              >
                Open
              </asom-badge>
              <asom-badge v-else class="ClosedStatus" variant="error">
                Closed
              </asom-badge>
            </div>
          </template>
          <template v-slot:header_primaryLocationName>Primary Location</template>
          <template v-slot:header_secondaryLocationName
            >Secondary Location</template
          >
          <template v-slot:expiryDate="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
        </asom-client-table>
        <h4 class="text-md font-semibold">Transactions</h4>
        <asom-client-table
          :columns="[
            'transactionNo',
            'transactionType',
            'createdBy',
            'dateCreated',
            'primaryLocationName',
            'secondaryLocationName',
            'quantity',
            'remarks',
          ]"
          :data="transactionData"
          :filterable="false"
        >
          <template v-slot:dateCreated="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
        </asom-client-table>
      </asom-card>
    </div>
    <template v-if="!readonly">
      <asom-form-field label="Confirmation">
        <asom-input-radio-group
          horizontal
          v-model="computedAligned"
          :options="alignOptions"
        />
      </asom-form-field>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
        required
        description="Remarks is required for discrepancy"
        :state="inputStates('smartCardSummary.remarks')"
        error="Remarks must be between 10 and 1000 characters long"
      >
        <asom-input-textarea v-model="computedRemarks" />
      </asom-form-field>
    </template>
    <template v-else>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
      >
        <p>{{remarks}}</p>
      </asom-form-field>
    </template>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { inject } from "vue";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import { baseSummaryMixin, summaryRemarksMixin } from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryRemarksMixin],
  computed: {
    currentShiftData() {
      return get(this.data, 'data.currentShiftData.smartCards', []);
    },
    previousShiftData() {
      return get(this.data, "previousShiftData.smartCards", []);
    },
    transactionData() {
      return get(this.data, "data.transactions", []);
    },
    smartCardCount() {
      return get(this.data, "smartCardCount", "NA");
    },
  },
  methods: {
    displayUtcDate,
    get,
  },
};
</script>
<style scoped>
.StatusBlock {
  @apply flex;
}
</style>
