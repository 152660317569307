<template>
  <shift-handover-accordion-item title="GTM/TUK Cash Boxes" :status="status">
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <h4 class="text-md font-semibold">Previous Shift Info</h4>
      <gtm-cash-box-table
        :gtmCashBoxes="get(previousShiftData, 'gtmCashBoxes', [])"
        :afcCoinBoxes="get(previousShiftData, 'afcCoinBoxes', [])"
        :afcNoteBoxes="get(previousShiftData, 'afcNoteBoxes', [])"
      />
      <h4 class="text-md font-semibold">Current Shift Info</h4>
      <gtm-cash-box-table
        :gtmCashBoxes="get(currentShiftData, 'gtmCashBoxes', [])"
        :afcCoinBoxes="get(currentShiftData, 'afcCoinBoxes', [])"
        :afcNoteBoxes="get(currentShiftData, 'afcNoteBoxes', [])"
      />
      <h4 class="text-md font-semibold">Transactions</h4>
      <asom-client-table
        :columns="[
          'transactionNo',
          'cashBoxSerialNumber',
          'cashBoxType',
          'amount',
          'cashBoxStatus',
          'cashBoxTransationType',
          'documentReference',
          'location',
          'createdBy',
          'rosterDWS',
        ]"
        :data="transactionData"
        :filterable="false"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
      </asom-client-table>
      
      <template v-if="!readonly">
        <asom-form-field label="Confirmation">
          <asom-input-radio-group
            horizontal
            v-model="computedAligned"
            :options="alignOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="!isAligned"
          label="Discrepancy Report"
          required
          description="Discrepancy report must be selected"
          :state="inputStates('gtmCashBoxSummary.discrepancyReports')"
          error="No discrepancy report added"
        >
          <span class="pr-4">{{
            selectedDiscrepancyReports.length > 0
              ? selectedDiscrepancyReports.length + ` Selected `
              : null
          }}</span>
          <asom-button
            :text="selectedDiscrepancyReports.length > 0 ? 'Edit' : 'Add'"
            :icon="selectedDiscrepancyReports.length > 0 ? 'pen' : 'add-r'"
            icon-position="left"
            @click="addReports"
          ></asom-button>
        </asom-form-field>
        <discrepancy-report-selection
          v-model="showReportSelection"
          @selectedItems="setReports($event)"
          :selected-reports="selectedDiscrepancyReports"
        />
      </template>
      <template v-else-if="!isAligned">
        <h4 class="text-md font-semibold">Discrepancy Reports</h4>
        <asom-client-table
          :columns="[
            'no',
            'referenceNo',
            'description',
          ]"
          :data="discrepancyReports"
          :filterable="false"
          :pagination="false"
        > 
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
      </template>
    </div>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import DiscrepancyReportSelection from "./_DiscrepancyReportSelection.vue";
import GTMCashBoxTable from "./_GTMCashBoxTable.vue"
import {
  baseSummaryMixin,
  summaryDiscrepancyReportMixin,
} from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
    DiscrepancyReportSelection,
    'gtm-cash-box-table': GTMCashBoxTable
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryDiscrepancyReportMixin],
  data() {
    return {
      showReportSelection: false,
      selectedDiscrepancyReports: [],
    };
  },
  computed: {
    previousShiftData() {
      return get(this.data, 'previousShiftData');
    },
    currentShiftData() {
      return get(this.data, 'currentShiftData');
    },
    transactionData() {
      return get(this.data, "transactions", []);
    },
  },
  methods: {
    displayUtcDate,
    get,
    addReports() {
      this.showReportSelection = true;
    },
    setReports(reports) {
      this.selectedDiscrepancyReports = reports;
      this.computedDiscrepancyReports = reports;
    },
  },
};
</script>
