<template>
  <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
    <table class="min-w-full divide-y divide-gray-200">
      <caption class="hidden"></caption>
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            GTM/TUK Cashbox
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            <p class="flex items-center space-x-2">
              <asom-icon :icon="'dollar'" size="sm" />
              <span>AFC Notebox</span>
            </p>
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center"
          >
            <p class="flex items-center space-x-2">
              <asom-icon :icon="'shape-circle'" size="sm" />
              <span>AFC Coinbox</span>
            </p>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 text-sm">
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">
            <template v-for="box in gtmNoteBoxes" :key="box.cashBoxId">
              <p class="flex items-center space-x-2">
                <asom-icon :icon="'dollar'" size="sm" />
                <span>{{ box.cashBoxSerialNumber }}</span>
              </p>
            </template>
            <template v-for="box in gtmCoinBoxes" :key="box.cashBoxId">
              <p class="flex items-center space-x-2">
                <asom-icon :icon="'shape-circle'" size="sm" />
                <span>{{ box.cashBoxSerialNumber }}</span>
              </p>
            </template>
          </td>
          <td class="px-6 py-4 whitespace-nowrap align-top">
            <article class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
              <aside>
                <label class="font-bold">Pending Collection</label>
                <template
                  v-for="box in afcFullNoteBoxes"
                  :key="box.cashBoxId"
                >
                  <p>{{ box.cashBoxSerialNumber }}</p>
                </template>
              </aside>
              <aside>
                <label class="font-bold">Empty</label>
                <template
                  v-for="box in afcEmptyNoteBoxes"
                  :key="box.cashBoxId"
                >
                  <p>{{ box.cashBoxSerialNumber }}</p>
                </template>
              </aside>
            </article>
          </td>
          <td class="px-6 py-4 whitespace-nowrap align-top">
            <article class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
              <aside>
                <label class="font-bold">Pending Collection</label>
                <template
                  v-for="box in afcFullCoinBoxes"
                  :key="box.cashBoxId"
                >
                  <p>{{ box.cashBoxSerialNumber }}</p>
                </template>
              </aside>
              <aside>
                <label class="font-bold">Empty</label>
                <template
                  v-for="box in afcEmptyCoinBoxes"
                  :key="box.cashBoxId"
                >
                  <p>{{ box.cashBoxSerialNumber }}</p>
                </template>
              </aside>
            </article>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    gtmCashBoxes: {
      type: Array,
      default: () => []
    },
    afcNoteBoxes: {
      type: Array,
      default: () => []
    },
    afcCoinBoxes: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    gtmNoteBoxes() {
      return this.gtmCashBoxes.filter(
        ({ isNoteBox }) => isNoteBox
      );
    },
    gtmCoinBoxes() {
      return this.gtmCashBoxes.filter(
        ({ isNoteBox }) => !isNoteBox
      );
    },
    afcFullNoteBoxes() {
      return this.afcNoteBoxes.filter(
        ({ statusEnum }) => statusEnum === 3
      );
    },
    afcFullCoinBoxes() {
      return this.afcCoinBoxes.filter(
        ({ statusEnum }) => statusEnum === 3
      );
    },
    afcEmptyNoteBoxes() {
      return this.afcNoteBoxes.filter(
        ({ statusEnum }) => statusEnum === 1
      );
    },
    afcEmptyCoinBoxes() {
      return this.afcCoinBoxes.filter(
        ({ statusEnum }) => statusEnum === 1
      );
    },
  },
}
</script>

<style>

</style>