export const mainPageItemTypes = {
  outOfStock: {
    VALUE: 1,
  },
  lowInventory: {
    VALUE: 2,
  },
  nearExpiry: {
    VALUE: 3,
  },
  expiredStock: {
    VALUE: 4,
  },
};

export const psc = {
  psmManualRecord: {
    VALUE: 1,
  },
  preEncodedTicket: {
    TRANSACTION_TYPE: {
      PROVISION: {
        NAME: "Provisioned",
        VALUE: 1,
      },
      ISSUE: {
        NAME: "Issued",
        VALUE: 2,
      },
      SPARE: {
        NAME: "Closed",
        VALUE: 3,
      },
    },
    VALUE: 2,
  },
  cashFloatELog: {
    TE_BAG_TRANSACTION_TYPE: {
      CREATE: {
        NAME: "Create",
        VALUE: 1,
      },
      MOVE_OUT: {
        NAME: "Move Out",
        VALUE: 2,
      },
      MOVE_IN: {
        NAME: "Move In",
        VALUE: 3,
      },
      COLLECT: {
        NAME: "Collect",
        VALUE: 4,
      },
    },
    CERTIS_BAG_STATUS: {
      SEALED: 1,
      OPENED: 2,
      DISCARDED: 3,
      REJECTED: 4,
      COLLECTED: 5,
    },
    CERTIS_BAG_TRANSACTION_TYPE: {
      CREATE: {
        NAME: "Create",
        VALUE: 1,
      },
      MOVE_OUT: {
        NAME: "Move Out",
        VALUE: 2,
      },
      MOVE_IN: {
        NAME: "Move In",
        VALUE: 3,
      },
      OPEN: {
        NAME: "Open",
        VALUE: 4,
      },
      DISCARD: {
        NAME: "Discard",
        VALUE: 5,
      },
    },
    VALUE: 3,
  },
  coinFlush: {
    VALUE: 4,
  },
};

export const discrepancyNature = {
  OTHERS: {
    NAME: "Others ",
    VALUE: 0,
  },
  PSM_MANUAL_RECORD: {
    NAME: "PSM Manual Record",
    VALUE: 1,
  },
  CASH_FLOAT: {
    NAME: "Manual Change",
    VALUE: 2,
  },
  PRE_ENCODED_TICKET: {
    NAME: "Pre Encoded Ticket",
    VALUE: 3,
  },
  CASH_BAG: {
    NAME: "Cash Bag",
    VALUE: 4,
  },
  CASH_BOX: {
    NAME: "Cash Box",
    VALUE: 5,
  },
  COIN_FLOAT: {
    NAME: "Coin Float",
    VALUE: 6,
  },
  SCCF: {
    NAME: "Station Cash Collection",
    VALUE: 7,
  },
  STATION_TRANSFER: {
    NAME: "Station Transfer",
    VALUE: 8,
  },
  CASH_DECLARATION_AFC: {
    NAME: "Cash Declaration(AFC)",
    VALUE: 9,
  },
  CASH_DECLARATION_PSC: {
    NAME: "Cash Declaration(PSC)",
    VALUE: 10,
  },
};

export const discrepancyReportStatus = {
  IN_REVIEW: {
    NAME: "In Review",
    VALUE: 1,
  },
  APPROVED: {
    NAME: "Approved",
    VALUE: 2,
  },
  CLOSED: {
    NAME: "Closed",
    VALUE: 3,
  },
};

export const cdf = {
  cdfStatus: {
    IN_PROGRESS: {
      NAME: "In Progress",
      VALUE: 1,
    },
    PENDING_WITNESS_SIGNATURE: {
      NAME: "Pending Witness Signature",
      VALUE: 2,
    },
    PENDING_BAGGER_SIGNATURE: {
      NAME: "Pending Bagger Signature",
      VALUE: 3,
    },
    COMPLETED: {
      NAME: "Completed",
      VALUE: 4,
    },
    DELETED: {
      VALUE: 5,
    },
  },
  sourceOfCash: {
    PSM: 1,
    PreEncodedTicket: 2,
    CashFloat: 3,
    CoinFlush: 4,
    Others: 5,
  },
};

export const cashOrder = {
  cashOrderStatus: {
    IN_REVIEW: {
      NAME: "In Review",
      VALUE: 1,
    },
    REJECTED: {
      NAME: "Rejected",
      VALUE: 2,
    },
    PENDING_DELIVERY: {
      NAME: "Pending Delivery",
      VALUE: 3,
    },
    PENDING_PICKUP: {
      NAME: "Pending Pickup",
      VALUE: 4,
    },
    PENDING_CONFIRM_PICKUP: {
      NAME: "Pending Pickup Confirmation",
      VALUE: 5,
    },
    DELIVERY_FULFILLED: {
      NAME: "Delivery Fulfilled",
      VALUE: 6,
    },
    DELIVERY_REJECTED: {
      NAME: "Delivery Rejected",
      VALUE: 7,
    },
  },
};
