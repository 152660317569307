<template>
  <shift-handover-accordion-item title="AFC Store Spare Key Press" :status="status">
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <asom-form-field v-else label="Information">
      <div id="text-holder" class="border border-gray-600 p-4 rounded-md" v-dompurify-html="data"></div>
    </asom-form-field>
    <template v-if="!readonly">
      <asom-form-field label="Confirmation">
        <asom-input-radio-group
          horizontal
          v-model="computedAligned"
          :options="alignOptions"
        />
      </asom-form-field>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
        required
        description="Remarks is required for discrepancy"
        :state="inputStates('afcKeySummary.remarks')"
        error="Remarks must be between 10 and 1000 characters long"
      >
        <asom-input-textarea
          v-model="computedRemarks"
          :state="inputStates('afcKeySummary.remarks')"
        />
      </asom-form-field>
    </template>
    <template v-else>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
      >
        <p>{{remarks}}</p>
      </asom-form-field>
    </template>
  </shift-handover-accordion-item>
</template>

<script>
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import { baseSummaryMixin, summaryRemarksMixin } from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryRemarksMixin],
};
</script>
