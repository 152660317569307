<template>
  <div
    class="max-w-md rounded-lg bg-gray-100 px-6 py-5 shadow items-center"
  >
    <div class="p-2 h-10 w-10 bg-button rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
    <div class="pt-4">
      <p class="font-sans font-bold text-gray-500 text-3xl">
        {{ formatCurrency(psmAmount) }}
      </p>
      <p class="font-sans font-semibold text-gray-500">
        PSM Manual Record Float Adj (+/-)
      </p>
    </div>
    <div class="pt-6">
      <div>
        <span class="font-sans text-button">{{
          formatCurrency(psmInflowAmount)
        }}</span>
        <span class="font-sans text-gray-500"> PSM Float Adjustment (+)</span>
      </div>
      <div>
        <span class="font-sans text-button">{{
          formatCurrency(psmOutflowAmount)
        }}</span>
        <span class="font-sans text-gray-500"> PSM Float Adjustment (-)</span>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCurrency } from "@/helpers/numbers";
export default {
  props: {
    psmAmount: {
      type: Number,
      required: true,
    },
    psmInflowAmount: {
      type: Number,
      required: true,
    },
    psmOutflowAmount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatCurrency,
  }
}
</script>

<style>

</style>