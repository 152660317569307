<template>
  <shift-handover-accordion-item title="GTM/TUK Coin Float" :status="status">
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <div class="AsomTable__TableContainer mb-4">
        <table class="AsomTable divide-y divide-gray-200 tbl_cm">
          <caption class="hidden"></caption>
          <thead>
            <tr>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col"> 
              </th>
              <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                10¢
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                20¢
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                50¢
              </th>
              <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" scope="col">
                $1
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr>
              <td>Start of Shift<br/>(Number of coins)</td>
              <td>{{get(data, 'previousShiftData.balanceOf10CentTubes', 0)}}</td>
              <td>{{get(data, 'previousShiftData.balanceOf20CentTubes', 0)}}</td>
              <td>{{get(data, 'previousShiftData.balanceOf50CentTubes', 0)}}</td>
              <td>{{get(data, 'previousShiftData.balanceOf1DollarTubes', 0)}}</td>
            </tr>
            <tr>
              <td>End of shift<br/>(Number of coins)</td>
              <td>{{get(data, 'currentShiftData.balanceOf10CentTubes', 0)}}</td>
              <td>{{get(data, 'currentShiftData.balanceOf20CentTubes', 0)}}</td>
              <td>{{get(data, 'currentShiftData.balanceOf50CentTubes', 0)}}</td>
              <td>{{get(data, 'currentShiftData.balanceOf1DollarTubes', 0)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <asom-client-table
        :columns="[
          'transactionNo',
          'bagNo',
          'amount',
          'dateCreated',
          'createdBy',
          'isCertisBag',
          'lastLocationName',
          'rosterDWS',
          'transactionType',
          'remarks',
        ]"
        :data="transactionData"
        :filterable="false"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
      </asom-client-table>
      <template v-if="!readonly">
        <asom-form-field label="Confirmation">
          <asom-input-radio-group
            horizontal
            v-model="computedAligned"
            :options="alignOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="!isAligned"
          label="Discrepancy Report"
          required
          description="Discrepancy report must be selected"
          :state="inputStates('gtmCoinFloatSummary.discrepancyReports')"
          error="No discrepancy report added"
        >
          <span class="pr-4">{{
            selectedDiscrepancyReports.length > 0
              ? selectedDiscrepancyReports.length + ` Selected `
              : null
          }}</span>
          <asom-button
            :text="selectedDiscrepancyReports.length > 0 ? 'Edit' : 'Add'"
            :icon="selectedDiscrepancyReports.length > 0 ? 'pen' : 'add-r'"
            icon-position="left"
            @click="addReports"
          ></asom-button>
        </asom-form-field>
        <discrepancy-report-selection
          v-model="showReportSelection"
          @selectedItems="setReports($event)"
          :selected-reports="selectedDiscrepancyReports"
        />
      </template>
      <template v-else-if="!isAligned">
        <h4 class="text-md font-semibold">Discrepancy Reports</h4>
        <asom-client-table
          :columns="[
            'no',
            'referenceNo',
            'description',
          ]"
          :data="discrepancyReports"
          :filterable="false"
          :pagination="false"
        > 
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
      </template>
    </div>    
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import DiscrepancyReportSelection from "./_DiscrepancyReportSelection.vue";
import {
  baseSummaryMixin,
  summaryDiscrepancyReportMixin,
} from "./summaryMixins";

export default {
  components: {
    ShiftHandoverAccordionItem,
    DiscrepancyReportSelection,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryDiscrepancyReportMixin],
  data() {
    return {
      showReportSelection: false,
      selectedDiscrepancyReports: [],
    };
  },
  computed: {
    transactionData() {
      return get(this.data, "transactions", []);
    },
  },
  methods: {
    get,
    displayUtcDate,
    addReports() {
      this.showReportSelection = true;
    },
    setReports(reports) {
      this.selectedDiscrepancyReports = reports;
      this.computedDiscrepancyReports = reports;
    },
  },
};
</script>
