<template>
  <article
    class="no-print grid sm:grid-cols-1 md:grid-cols-4 bg-white overflow-hidden shadow rounded-lg divide-x divide-gray-200"
  >
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <asom-icon icon="ui-kit" />
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Station</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{stationName}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <asom-icon icon="performance" />
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Roster</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{rosterDWS}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <asom-icon icon="time" />
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Last Shift Handover End Time</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{lastShiftEndTime}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="px-5 py-3">
      <div class="flex items-start h-full">
        <div class="flex-shrink-0">
          <asom-icon icon="time" />
        </div>
        <div class="ml-5 w-0 flex-1 justify-start h-full">
          <dl>
            <dt class="text-sm font-medium text-gray-500 truncate">Current Shift End Time</dt>
            <dd>
              <div class="text-lg font-medium text-gray-900">{{currentTime}}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { displayUtcDateTime, displayDateTime } from '@/helpers/dateTimeHelpers'

export default {
  props: {
    rosterDWS: {
      type: String,
    },
    stationName: {
      type: String,
    },
    startTime: {
      type: Date,
      default: null,
    },
    endTime: {
      type: Date,
      default: null,
    }
  },
  computed: {
    lastShiftEndTime() {
      if (this.startTime)
        return displayDateTime(this.startTime, 'DD MMM, HH:mm');
      return 'No shift handover found';
    },
    currentTime() {
      if (this.endTime)
        return displayUtcDateTime(this.endTime, 'DD MMM, HH:mm');
      return 'End time not found';
    }
  },
}
</script>