<template>
  <shift-handover-accordion-item title="Outstanding Faults" :status="status">
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <asom-card class="p-2">
        <asom-client-table
          v-if="!isLoading"
          :columns="[
            'faultNo',
            'description',
            'dateCreated',
            'timeCreated',
            'createdBy',
            'notificationId',
            'workOrderId',
            'workOrderStatus',
            'isMaintainanceStaffInformed',
            'workCenter',
            'remarks',
          ]"
          :sortableColumns="[
            'faultNo',
            'description',
            'tragNo',
            'workOrderId',
            'workOrderStatus',
            'isMaintainanceStaffInformed',
            'workCenter',
          ]"
          :data="tableData"
          :filterable="false"
        >
          <template v-slot:header_workCenter>Work Centre</template>
          <template v-slot:header_notificationId
            >Notification ID/ Trag No / OCC No.</template
          >
          <template v-slot:notificationId="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:workOrderId="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:workOrderStatus="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
          </template>
          <template v-slot:dateCreated="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:timeCreated="scopedSlots">
            <span>{{
              displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
            }}</span>
          </template>
          <template v-slot:isMaintainanceStaffInformed="scopedSlots">
            <span>{{ scopedSlots.data ? "YES" : "NO" }}</span>
          </template>
          <template v-slot:remarks="scopedSlots">
            <span v-if="scopedSlots.data"
              ><remarks-tooltip :text="scopedSlots.data"/></span
            ><span v-else>{{ "NA" }}</span>
          </template>
        </asom-client-table>
      </asom-card>
    </div>
    <template v-if="!readonly">
      <asom-form-field label="Confirmation">
        <asom-input-radio-group
          horizontal
          v-model="computedAligned"
          :options="alignOptions"
        />
      </asom-form-field>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
        required
        description="Remarks is required for discrepancy"
        :state="inputStates('faultSummary.remarks')"
        error="Remarks must be between 10 and 1000 characters long"
      >
        <asom-input-textarea
          v-model="computedRemarks"
          :state="inputStates('faultSummary.remarks')"
        />
      </asom-form-field>
    </template>
    <template v-else>
      <asom-form-field
        v-if="!isAligned"
        label="Remarks"
      >
        <p>{{remarks}}</p>
      </asom-form-field>
    </template>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import { baseSummaryMixin, summaryRemarksMixin } from "./summaryMixins";
import RemarksTooltip from "../../_RemarksTooltip.vue";

export default {
  components: {
    ShiftHandoverAccordionItem,
    RemarksTooltip,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryRemarksMixin],
  computed: {
    tableData() {
      return get(this.data, "outstandingFaults", []);
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
  },
};
</script>
