<template>
  <shift-handover-accordion-item
    title="PSM Shift End Summary"
    :status="status"
  >
    <div v-if="loading"></div>
    <asom-alert v-else-if="error" variant="error" :error-message="error" />
    <div v-else class="space-y-4">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field class="col-span-1" label="Previous Shift Info">
          <psm-shift-data
            :psm-amount="get(previousShiftData, 'psmAmount', 0)"
            :psm-inflow-amount="get(previousShiftData, 'psmInflowAmount', 0)"
            :psm-outflow-amount="get(previousShiftData, 'psmOutflowAmount', 0)"
          />
        </asom-form-field>
        <asom-form-field  class="col-span-1" label="Current Shift Info">
          <psm-shift-data
            :psm-amount="get(currentShiftData, 'psmAmount', 0)"
            :psm-inflow-amount="get(currentShiftData, 'psmInflowAmount', 0)"
            :psm-outflow-amount="get(currentShiftData, 'psmOutflowAmount', 0)"
          />
        </asom-form-field>
        <asom-form-field class="col-span-2" label="Transactions">
          <asom-client-table
            :columns="[
              'transactionNo',
              'inputType',
              'transactionStatus',
              'description',
              'waived',
              'payment',
              'receipt',
              'amount',
            ]"
            :data="transactions"
            :sortableColumns="[
              'inputType',
              'description',
              'waived',
              'payment',
              'receipt',
              'amount',
            ]"
            :filterable="false"
            :pagination="false"
          >
            <template v-slot:header_inputType>Input Type</template>
            <template v-slot:inputType="scopedSlots">
              <span>{{ inputType(scopedSlots.data) }}</span>
            </template>
            <template v-slot:header_transactionNo>Transaction No</template>
            <template v-slot:header_transactionStatus>Transaction Status</template>

            <template v-slot:header_amount>Total</template>
            <template v-slot:waived="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:payment="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:receipt="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
            <template v-slot:amount="scopedSlots">{{
              formatCurrency(scopedSlots.data)
            }}</template>
          </asom-client-table>
        </asom-form-field>
      </div>
      <template v-if="!readonly">
        <asom-form-field label="Confirmation">
          <asom-input-radio-group
            horizontal
            v-model="computedAligned"
            :options="alignOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="!isAligned"
          label="Discrepancy Reports"
          required
          description="Discrepancy reports must be selected"
          :state="inputStates('psmSummary.discrepancyReports')"
          error="No discrepancy report added"
        >
          <span class="pr-4">{{
            selectedDiscrepancyReports.length > 0
              ? selectedDiscrepancyReports.length + ` Selected `
              : null
          }}</span>
          <asom-button
            :text="selectedDiscrepancyReports.length > 0 ? 'Edit' : 'Add'"
            :icon="selectedDiscrepancyReports.length > 0 ? 'pen' : 'add-r'"
            icon-position="left"
            @click="addReports"
          ></asom-button>
        </asom-form-field>
        <discrepancy-report-selection
          v-model="showReportSelection"
          @selectedItems="setReports($event)"
          :selected-reports="selectedDiscrepancyReports"
        />
      </template>
      <template v-else-if="!isAligned">
        <h4 class="text-md font-semibold">Discrepancy Reports</h4>
        <asom-client-table
          :columns="[
            'no',
            'referenceNo',
            'description',
          ]"
          :data="discrepancyReports"
          :filterable="false"
          :pagination="false"
        > 
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
        </asom-client-table>
      </template>
    </div>
  </shift-handover-accordion-item>
</template>

<script>
import get from "lodash.get";
import { inject } from "vue";
import inputStates from "@/mixins/inputStates";
import ShiftHandoverAccordionItem from "./_ShiftHandoverAccordionItem.vue";
import {
  baseSummaryMixin,
  summaryDiscrepancyReportMixin,
} from "./summaryMixins";
import DiscrepancyReportSelection from "./_DiscrepancyReportSelection.vue";
import PSMShiftData from './PSMShiftData.vue'
import { formatCurrency } from "@/helpers/numbers";

export default {
  components: {
    ShiftHandoverAccordionItem,
    DiscrepancyReportSelection,
    'psm-shift-data': PSMShiftData,
  },
  setup() {
    const v$ = inject("v$");
    return { v$ };
  },
  mixins: [inputStates, baseSummaryMixin, summaryDiscrepancyReportMixin],
  data() {
    return {
      showReportSelection: false,
      selectedDiscrepancyReports: [],
    };
  },
  computed: {
    currentShiftData() {
      return get(this.data, "currentShiftData");
    },
    previousShiftData() {
      return get(this.data, "previousShiftData");
    },
    transactions() {
      return get(this.data, "transactions");
    },
  },
  methods: {
    get,
    formatCurrency,
    addReports() {
      this.showReportSelection = true;
    },
    setReports(reports) {
      this.selectedDiscrepancyReports = reports;
      this.computedDiscrepancyReports = reports;
    },
    inputType(type) {
      if (type == 1) {
        return "Passenger name";
      } else if (type == 2) {
        return "CAN ID";
      } else if (type == 3) {
        return "Notebox No.";
      } else if (type == 4) {
        return "Coinbox No.";
      } else if (type == 5) {
        return "PSM Top-up";
      } else if (type == 6) {
        return "Others";
      } else {
        return "";
      }
    },
  },
};
</script>
